import React from 'react'
import { styled } from '@mui/material'
import collapseArrow from '../../../assets/images/icons/collapse-arrow.svg'

const CellItem = styled('span', { shouldForwardProp: (prop) => prop !== 'collapse' })<{ collapse: boolean }>(
  ({ theme, collapse }) => ({
    position: 'relative',
    ...(collapse && {
      '&::after': {
        content: '""',
        position: 'absolute',
        display: 'block',
        width: '6px',
        height: '6px',
        background: `url(${collapseArrow}) no-repeat`,
        backgroundSize: 'contain',
        bottom: '-5px',
        right: '-12px'
      }
    })
  })
)

interface CollapseCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
  collapse: boolean
}

export const CollapseCell: React.FC<CollapseCellProps> = ({ children, collapse, ...rest }) => {
  return (
    <td {...rest}>
      <CellItem collapse={collapse}>{children}</CellItem>
    </td>
  )
}
