import { Box, CircularProgress, Grow, Paper, Popper, Typography } from '@mui/material'
import React from 'react'
import { VgTable, VgTableColumnType } from '../../../components/VgTable/VgTable'
import { useTypeSelector } from '../../../hooks/useTypeSelector'
import { StyledLinkRouter } from '../../../infrasturcture/theme/styled'
import { Count } from '../../../components/Operations/Count'
import { BookingInBalance } from '../../../types/bookingsTypes'
import styles from './ActiveBookingPopper.module.css'
import { useMediaQueryContext } from '../../../contexts/MediaQueryContext'

const bookingsColumns: VgTableColumnType<BookingInBalance>[] = [
  {
    key: 'sku',
    title: 'Номер бронирования',
    render: (_, { sku }) => (
      <StyledLinkRouter target="_blank" to={`/bookings/${sku}`} sx={{ fontSize: '12px' }}>
        {sku}
      </StyledLinkRouter>
    ),
    width: '53%',
    textAlign: 'center',
    gridArea: [1, 1, 2, 3]
  },
  {
    key: 'count',
    title: 'Количество',
    render: (_, { count }) => <Count variant="small">{count}</Count>,
    width: '47%',
    textAlign: 'center',
    gridArea: [2, 1, 3, 3]
  }
]

interface ActiveBookingsPopperProps {
  anchorEl: HTMLElement | null
}

export const ActiveBookingsPopper = React.forwardRef<HTMLDivElement, ActiveBookingsPopperProps>(({ anchorEl }, ref) => {
  const { isMobile } = useMediaQueryContext()
  const { bookingsInBalances, isLoaded } = useTypeSelector((state) => state.bookings)
  const open = Boolean(anchorEl)

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="right-start"
      transition
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [50, -25]
          }
        }
      ]}
      style={{ zIndex: 100 }}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'center bottom'
          }}
        >
          <Paper
            ref={ref}
            sx={{
              display: 'flex',
              width: { xs: 170, md: 232 },
              minHeight: 88,
              maxHeight: 216,
              padding: '8px 0 8px 8px',
              backgroundColor: '#FCFCFC'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                paddingRight: { xs: '6px', md: '8px' },
                flex: 1,
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: '6px',
                  borderRadius: '8px'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: (theme) => theme.palette.text.primary,
                  borderRadius: '8px',
                  cursor: 'default'
                }
              }}
            >
              {isLoaded ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                  <CircularProgress sx={{ width: '20px !important', height: '20px !important' }} color="primary" />
                </Box>
              ) : bookingsInBalances.length > 0 ? (
                <VgTable className={styles.table} columns={bookingsColumns} data={bookingsInBalances} mobileMode={isMobile} />
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, p: '0px 30px' }}>
                  <Typography
                    variant="h2"
                    color="text.primary"
                    sx={{ fontSize: '12px', lineHeight: '14px', fontWeight: 400, textAlign: 'center' }}
                  >
                    Активные бронирования отсутствуют
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
})
