/* eslint-disable no-unused-vars */

import { ErrorMessages } from './errorTypes'
import { OperationsData } from './operationsTypes'
import { Order, OrderFilters, OrderPostingProduct } from './ordersTypes'
import { Product } from './productsTypes'

export enum statusTypes {
  CONFIRMATION = 'CONFIRMATION',
  IN_PROCESS = 'IN_PROCESS',
  READY = 'READY',
  PART_SHIPPED = 'PART_SHIPPED',
  SHIPPED_CLOSED = 'SHIPPED_CLOSED',
  CANCELED = 'CANCELED'
}

export const statusTypesRus: { [key: string]: string } = {
  CONFIRMATION: 'Подтверждение',
  IN_PROCESS: 'В процессе',
  READY: 'Готово',
  PART_SHIPPED: 'Частично отгружено',
  SHIPPED_CLOSED: 'Отгружено и закрыто',
  CANCELED: 'Отменено'
}

export interface BookingProduct extends Product {
  count: number
}

export interface ProductInBooking extends Omit<Product, 'description' | 'name_1c'> {
  count: number
  prev_count?: number
  write_off_left: number
  products_audit_id?: string | null
}

export interface Booking extends Order {
  productsInBooking?: ProductInBooking[]
}

export interface BookingDoneProduct extends Omit<OrderPostingProduct, 'checkbox'> {
  isBooking: boolean
}

export interface BookingDone extends Omit<Booking, 'productsInBooking' | 'productsInOrder'> {
  productsInBooking: BookingDoneProduct[]
  productsInOrder: BookingDoneProduct[]
}

export interface BookingInBalance {
  sku: string
  count: number
}

export interface BookingsState {
  bookings: Booking[]
  bookingsDone: BookingDone[]
  selectedBookingsDone: BookingDoneProduct[]
  booking: Booking | null
  products: BookingProduct[]
  productsInBooking: ProductInBooking[]
  originalProducts: ProductInBooking[]
  bookingsInBalances: BookingInBalance[]
  limit: number
  count: number
  countQuickFilter: number
  isLoaded: boolean
  filters: OrderFilters
  countErrors: { id: string; message: string } | null
  productsError: string | null
  errors: ErrorMessages
}

export enum BookingsActionTypes {
  GET_BOOKINGS = 'GET_BOOKINGS',
  GET_BOOKING = 'GET_BOOKING',
  SET_BOOKING = 'SET_BOOKING',
  GET_BOOKINGS_DONE = 'GET_BOOKINGS_DONE',
  SET_BOOKINGS_DONE = 'SET_BOOKINGS_DONE',
  GET_BOOKINGS_IN_BALANCES = 'GET_BOOKINGS_IN_BALANCES',
  SET_BOOKINGS_IN_BALANCES = 'SET_BOOKINGS_IN_BALANCES',
  SELECT_BOOKINGS_DONE = 'SELECT_BOOKINGS_DONE',
  SET_BOOKINGS_DONE_PRODUCTS = 'SET_BOOKINGS_DONE_PRODUCTS',
  PLUS_BOOKINGS_DONE_PRODUCT = 'PLUS_BOOKINGS_DONE_PRODUCT',
  MINUS_BOOKINGS_DONE_PRODUCT = 'MINUS_BOOKINGS_DONE_PRODUCT',
  GET_BOOKINGS_PRODUCTS = 'GET_BOOKINGS_PRODUCTS',
  SET_BOOKINGS_LOADED = 'SET_BOOKINGS_LOADED',
  ADD_BOOKING_PRODUCT = 'ADD_BOOKING_PRODUCT',
  REMOVE_BOOKING_PRODUCT = 'REMOVE_BOOKING_PRODUCT',
  PLUS_BOOKING_PRODUCT = 'PLUS_BOOKING_PRODUCT',
  MINUS_BOOKING_PRODUCT = 'MINUS_BOOKING_PRODUCT',
  SET_BOOKING_FILTERS = 'SET_BOOKING_FILTERS',
  SET_BOOKINGS_COUNT_ERRORS = 'SET_BOOKINGS_COUNT_ERRORS',
  SET_BOOKING_PRODUCTS = 'SET_BOOKING_PRODUCTS',
  SET_BOOKINGS = 'SET_BOOKINGS',
  SET_BOOKING_SEARCH_PRODUCTS = 'SET_BOOKING_SEARCH_PRODUCTS',
  SET_BOOKINGS_COUNT = 'SET_BOOKINGS_COUNT',
  SET_BOOKINGS_ERRORS = 'SET_BOOKINGS_ERRORS'
}

export interface GetBookingsThunkCreator {
  type: BookingsActionTypes.GET_BOOKINGS
  payload: OperationsData<Booking>
}

export interface GetBookingThunkCreator {
  type: BookingsActionTypes.GET_BOOKING
  payload: Booking
}

export interface GetBookingsInBalancesThunkCreator {
  type: BookingsActionTypes.GET_BOOKINGS_IN_BALANCES
  payload: BookingInBalance[]
}

export interface SetBookingsInBalancesAction {
  type: BookingsActionTypes.SET_BOOKINGS_IN_BALANCES
  payload: BookingInBalance[]
}

export interface GetBookingsDoneThunkCreator {
  type: BookingsActionTypes.GET_BOOKINGS_DONE
  payload: BookingDone[]
}

export interface SetBooking {
  type: BookingsActionTypes.SET_BOOKING
  payload: Booking | null
}

export interface GetBookingsProductsInWarehouseThunkCreator {
  type: BookingsActionTypes.GET_BOOKINGS_PRODUCTS
  payload: BookingProduct[]
}

export interface SelectBookingsDone {
  type: BookingsActionTypes.SELECT_BOOKINGS_DONE
  payload: string
}

export interface SetBookingsDone {
  type: BookingsActionTypes.SET_BOOKINGS_DONE
  payload: BookingDone[]
}

export interface SetBookingsDoneProducts {
  type: BookingsActionTypes.SET_BOOKINGS_DONE_PRODUCTS
  payload: BookingDoneProduct[]
}

export interface PlusBookingsDoneProduct {
  type: BookingsActionTypes.PLUS_BOOKINGS_DONE_PRODUCT
  payload: { bookingId: string; productId: string; isBooking: boolean }
}

export interface MinusBookingsDoneProduct {
  type: BookingsActionTypes.MINUS_BOOKINGS_DONE_PRODUCT
  payload: { bookingId: string; productId: string; isBooking: boolean }
}

export interface AddBookingProduct {
  type: BookingsActionTypes.ADD_BOOKING_PRODUCT
  payload: ProductInBooking
}

export interface PlusBookingProduct {
  type: BookingsActionTypes.PLUS_BOOKING_PRODUCT
  payload: string
}

export interface MinusBookingProduct {
  type: BookingsActionTypes.MINUS_BOOKING_PRODUCT
  payload: string
}

export interface SetBookingFilters {
  type: BookingsActionTypes.SET_BOOKING_FILTERS
  payload: Partial<OrderFilters>
}

export interface SetBookingsLoaded {
  type: BookingsActionTypes.SET_BOOKINGS_LOADED
  payload: boolean
}

export interface SetBookingsCountErrors {
  type: BookingsActionTypes.SET_BOOKINGS_COUNT_ERRORS
  payload: { id: string; message: string } | null
}

export interface SetBookingProducts {
  type: BookingsActionTypes.SET_BOOKING_PRODUCTS
  payload: ProductInBooking[]
}

export interface SetBookingSearchProducts {
  type: BookingsActionTypes.SET_BOOKING_SEARCH_PRODUCTS
  payload: BookingProduct[]
}

export interface SetBookings {
  type: BookingsActionTypes.SET_BOOKINGS
  payload: Booking[]
}

export interface RemoveBookingProductAction {
  type: BookingsActionTypes.REMOVE_BOOKING_PRODUCT
  payload: string
}

export interface SetBookingsErrorsAction {
  type: BookingsActionTypes.SET_BOOKINGS_ERRORS
  payload: ErrorMessages
}

export type BookingsAction =
  | GetBookingsThunkCreator
  | GetBookingThunkCreator
  | GetBookingsDoneThunkCreator
  | SetBookingsLoaded
  | AddBookingProduct
  | PlusBookingProduct
  | MinusBookingProduct
  | GetBookingsProductsInWarehouseThunkCreator
  | SetBookingsCountErrors
  | SetBooking
  | SetBookingProducts
  | SetBookings
  | SetBookingSearchProducts
  | SelectBookingsDone
  | SetBookingsDone
  | SetBookingsDoneProducts
  | PlusBookingsDoneProduct
  | MinusBookingsDoneProduct
  | SetBookingFilters
  | SetBookingsErrorsAction
  | RemoveBookingProductAction
  | GetBookingsInBalancesThunkCreator
  | SetBookingsInBalancesAction
